.label {
	font-family: Raleway;
	font-size: 10px;
	line-height: 12px;
}

.tooltip-arrow {
	&__left {
		position: absolute;
		top: calc(100% + 1px);
		left: 5px;
	}

	&__right {
		position: absolute;
		top: calc(100% + 1px);
		right: 5px;
	}

	&__middle {
		position: absolute;
		top: calc(100% + 1px);
		left: calc(560px / 2);

		//transform: translateX(-50%);
	}
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
	box-shadow: 0 0 0 1000px var(--chakra-colors-gray-0) inset;

	transition: background-color 5000s ease-in-out 0s;

	-webkit-text-fill-color: var(--chakra-colors-gray-700);
}

.wide-tooltip {
	width: 577px;
	max-width: 100%;

	&__centered {
		width: 577px;
		max-width: 100%;

		text-align: center;
	}
}

.num-catalog-tooltip {
	width: 512px;
	max-width: 100%;	
}
