.error {
    display: flex;
    justify-content: center;

    width: 100vw;
    height: 100vh;
    margin-top: 200px;

    &__content {
        max-width: 407px;
        & img {
            margin-bottom: 40px;
        }
    }
}