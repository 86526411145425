.loader-wrapper {
	position: fixed;
	z-index: 10000;
	inset: 0;

	display: grid;
	place-items: center;

	background-color: var(--chakra-colors-white);
}
