.assembly-form {
	&-operation-separator {
		width: 100%;
		height: 1px;

		background-color: var(--chakra-colors-gray-100);
	}
}

.operations {
	display: flex;
	flex-direction: column;
	gap: 10px;
}
