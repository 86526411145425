.user-form {
	display: flex;
	flex-direction: column;
	gap: 12px;
}

.list {
	& li {
		margin-bottom: 8px;

		font-size: 12px;
		line-height: 16px;
	}

	&__marker {
		&-success::marker {
			color: #32d157;
		}
		&-danger::marker {
			color: #fc5e6e;
		}
		&-gray::marker {
			color: #718096;
		}
	}
}

.option-selected {
	padding-left: 20px !important;
	&::before {
		content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check" viewBox="0 0 16 16"><path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/></svg>');

		position: relative;
		top: 3px;
		right: 5px;
	}
}

:global {
	.tooltip .chakra-tooltip__arrow {
		left: -190px !important;
	}
}

.change-pass-wrapper {
	position: absolute;
	z-index: 11;
	top: -16px;
	left: -24px;

	display: block;

	width: calc(100% + 48px);
	padding: 16px 24px;

	background-color: white;
}
