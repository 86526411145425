.last-name, .first-name, .middle-name, .position {
	min-width: 300px;
	max-width: calc(100vw / 8);
}

.mail, .role {
	min-width: 200px;
	max-width: calc(100vw / 10);
}

.date {
	min-width: 150px;
	max-width: calc(100vw / 10);

	text-wrap: wrap;
}