.custom-select {
	& > :global(.ant-select-selector) {
		background-color: transparent !important;
		border-radius: 0 !important;
	}
}

.preform-select {
	:global(.ant-select-selector) {
		input {
			height: 100% !important;
		}
	}
	& > :global(.ant-select-selector) {
		align-items: center;
	}
}

.input-readonly {
	&:hover {
		cursor: default !important;

		background-color: white !important;
	}
}

.plan {
	@media print {
		width: 100px;
	}
}

.main-block {
	min-width: 775px;

	// правая колонка в блоке main (наименование + реш. о замене материала)
	.right-column-of-main {
		min-width: 220px;
		max-width: 220px;
	}

	table {
		th {
			height: var(--size-tabel-header);
			max-height: var(--size-tabel-header);

			line-height: 1;
			vertical-align: middle;
		}

		td {
			height: 35px;
		}
	}

	.text-in-cell {
		overflow: hidden;

		max-height: 35px;
		padding: 0;

		text-overflow: ellipsis;
		white-space: nowrap;

		&-wrap {
			line-height: 1;
			white-space: wrap;
		}
	}

	@media print {
		min-width: 740px;

		.card-number-wrapper {
			// gap: 24px;
		}

		.card-date-wrapper {
			gap: 14px !important;
		}

		.print_bold_font {
			// font-size: 16px;
			// font-weight: bold;
		}

		.right-column-of-main {
			min-width: 185px;
			max-width: 185px;
		}

		.without_border_bottom {
			border-bottom: 1px solid transparent !important;
		}

		table {
			td {
				height: 26px !important;

				font-size: var(--font-size-cell) !important;
				font-weight: var(--font-weight-cell);
				line-height: 1;

				border: 1px solid black !important;
			}

			th {
				height: var(--size-tabel-header);
				max-height: var(--size-tabel-header);

				font-size: var(--font-size-title) !important;
				font-weight: var(--font-weight-title) !important;

				border: 1px solid black !important;
			}
		}
	}
}

.edit-table > tr > td {
	padding: 0;
	div {
		height: 100% !important;
	}
	& > input,
	select,
	textarea,
	div > input {
		width: 100% !important;
		height: 100% !important;
		padding: 6px;

		border: none;
		border-radius: 0;
		&:hover:not(:focus) {
			cursor: pointer;

			background-color: #edf2f7;
		}
	}
	& > :global(.chakra-select__wrapper) {
		height: 100% !important;
	}
}

.main-block-preform {
	min-width: unset;

	@media print {
		min-width: unset;
	}
}
