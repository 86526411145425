.select {
	width: 100%;

	border: 1px solid #e2e8f0;
	border-radius: 4px;

	transition: 0.3s;
	&:hover {
		border: 1px solid #2d3748;
	}

	&-large {
		border-radius: 6px;
	}
	&-focus {
		border: 1px solid #0f9cfa;
		&:hover {
			border: 1px solid #0f9cfa;
		}
	}
	&-disabled {
		border: 1px solid #edf2f7;
	}
	&-invalid {
		border: 1px solid #fc5e6e;
		&:hover {
			border: 1px solid #fc5e6e;
		}
	}
}

:global {
	.rc-virtual-list .rc-virtual-list-holder div .rc-virtual-list-holder-inner div[aria-selected='true'] {
		.ant-select-item-option-content {
			&::before {
				content: url('data:image/svg+xml; utf8, <svg width="18" height="17" viewBox="5 0 25 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M21.85 6.68749C22.1917 6.91534 22.2841 7.37711 22.0562 7.71888L13.1313 21.1063C13.0077 21.2917 12.8075 21.4119 12.5857 21.4339C12.364 21.4558 12.1441 21.3772 11.9865 21.2197L6.03656 15.2697C5.74611 14.9792 5.74611 14.5083 6.03656 14.2179C6.32701 13.9274 6.79793 13.9274 7.08838 14.2179L12.3968 19.5263L20.8186 6.89377C21.0464 6.552 21.5082 6.45964 21.85 6.68749Z" fill="black"/></svg>');

				margin-right: 5px;

				color: black;
			}
		}
	}
}
