.menu {
	display: flex;
	align-items: center;

	justify-content: flex-end;

	&-icon {
		visibility: hidden;
	}
	&:hover .menu-icon {
		visibility: visible;
	}

	&-button {
		&:hover {
			color: var(--chakra-colors-primary-400);
		}
	}
}
