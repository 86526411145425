.notification {
  --px: 24px;
  --py: 16px;
  cursor: pointer;

  position: relative;

  gap: var(--chakra-radii-xl, 12px);

  width: 400px;
  padding: var(--py) var(--px);

  background: var(--gray-0, #fff);

  transition: 0.3s;

  &::before {
    content: "";

    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);

    width: 90%; /*or 100px*/
    height: 1px;

    border-bottom: 1px solid var(--chakra-colors-gray-300);
  }

  &:hover {
    /*stylelint-disable-next-line*/
    background: var(--chakra-colors-blackAlpha-200);
  }

  &__super-title {
    /*text-xs/lineHeight-4/font-semibold*/
    font-size: 12px;
    font-weight: 600;
    font-variant-numeric: lining-nums proportional-nums;
    line-height: 16px; /*133.333%*/
    color: var(--gray-500, #718096);
  }

  &__date {
    position: absolute;
    top: var(--py);
    right: var(--px);

    /*text-xs/lineHeight-4/font-medium*/
    font-size: 12px;
    font-weight: 500;
    font-variant-numeric: lining-nums proportional-nums;
    line-height: 16px; /*133.333%*/
    color: var(--gray-500, #718096);
  }

  &__title {
    /*text-sm/lineHeight-5/font-semibold*/
    font-size: 14px;
    font-weight: 600;
    line-height: 20px; /*142.857%*/
    color: var(--gray-700, #2d3748);
  }

  &__body {
    margin-block: 0.25rem;

    /*text-sm/lineHeight-5/font-medium*/
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px; /*142.857%*/
    color: var(--gray-700, #2d3748);
  }

  &__description {
    /*text-xs/lineHeight-4/font-normal*/
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px; /*133.333%*/
    color: var(--gray-500, #718096);
  }

  &__footer {
    display: flex;
    gap: 6px;
    align-items: center;
    justify-content: center;

    padding: 4px 8px;
  }

  &_unread {
    background: var(--danger-50, #fff5f6);

    &:hover {
      background: var(--danger-100, #fed7db);
    }
  }

  &__icon {
    position: absolute;
    top: 0;
    left: 0;

    display: grid;
    place-items: center;
  }


  &_rounded {
    border-radius: var(--chakra-radii-lg, 8px);
  }
}