.product-catalog__name {
	overflow: hidden;
	display: block;

	max-width: 370px;

	text-overflow: ellipsis;
	white-space: nowrap;
}

.phoc {
	overflow: hidden;

	width: 100%;

	text-overflow: ellipsis;
	white-space: nowrap;
}

.symbol-table {
	min-width: 150px;
	max-width: calc(100vw / 5);
}

.material-symbol {
	min-width: 150px;
	max-width: calc((100vw / 100) * 15);
}

.material-gost {
	min-width: 80px;
	max-width: calc((100vw / 100) * 10);
}

.material-sortament {
	min-width: 130px;
	max-width: calc((100vw / 100) * 10);
}

.material-gost-na-sortament {
	min-width: 160px;
	max-width: calc((100vw / 100) * 11);
}

.standard-consumption {
	min-width: 120px;
	max-width: calc((100vw / 100) * 6);
}