.tabs {
  padding-top: 24px;

  &__tab {
    font-size: 14px;
    font-weight: 500;
  }

  &__panel {
    padding: 0;

    font-size: 14px;
    font-weight: 500;
  }
}

.comments {
  display: flex;
  flex-direction: column;

  &__submit-button {
    align-self: end;

    font-size: 14px;
  }

   &__title {
    align-self: start;

    font-size: 1rem;
    font-weight: 600;
  }
}