.layout {
	--header-h-inner: 60px;
	--header-height: 92px; // with paddings
	--layout-gap: 16px;
	overflow: hidden;
	display: grid;
	grid-template-columns: var(--header-h-inner) 1fr;
	grid-template-rows: var(--header-h-inner) 1fr;
	gap: var(--layout-gap);

	width: 100vw;
	height: 100vh;
	padding: var(--layout-gap);

	background-color: var(--chakra-colors-surface-back);
	&-logo {
		display: grid;
		place-items: center;
	}
	&-main {
		position: relative;

		overflow: hidden;
		overflow-y: scroll;

		// TODO КОСТЫЛЬ, ПОТОМУ ЧТО ТАБЛИЦЫ В ANTD НЕ СЛЫШАЛИ ПРО ТО, ЧТО НУЖНО ПОДСТАРИВАТЬСЯ ПОД РОДИТЕЛЯ
		width: calc(100vw - (var(--layout-gap) * 3) - var(--header-h-inner));

		border-radius: 12px;
		box-shadow: var(--chakra-shadows-lg);

		&_full {
			grid-column: 1/3;

			width: calc(100vw - (var(--layout-gap) * 2));
		}
	}
}

.block {
	background-color: var(--chakra-colors-white);
	border-radius: 12px;

	&-main {
		background-color: var(--chakra-colors-white);
	}
}
