@import '../../tech-card.module';

.custom-select {
	& > :global(.ant-select-selector) {
		background-color: transparent !important;
		border-radius: 0 !important;
	}
}

.plan-block {
	display: flex;
	flex: 1.1;
	gap: 8px;
	justify-content: space-between;

	// min-width: 828px;
	min-width: 844px;

	table {
		// верхний блок плана (план шт, НР на кг, НР на м, К выдаче м, Подпись, ФИО ПДС) заголовки
		.plan_top_block_th {
			th {
				height: var(--size-tabel-header);
				max-height: var(--size-tabel-header);

				line-height: 1;
				vertical-align: middle;
			}
		}

		// верхний блок плана (план шт, НР на кг, НР на м, К выдаче м, Подпись, ФИО ПДС) ячейки
		.plan_top_block_td {
			td {
				height: 44px !important;
			}
		}

		// основной блок плана (Выдано, возврат) заголовки
		.plan_middle_block_th,
		.create_plan_middle_block_th {
			th {
				height: var(--size-tabel-header);
				max-height: var(--size-tabel-header);

				font-size: 10pt;
				font-weight: 400;
				line-height: 1;
				vertical-align: middle;
			}
		}

		// основной блок плана (Выдано, возврат) ячейки
		.plan_middle_block_td,
		.create_plan_middle_block_td {
			td {
				height: 35px !important;
			}
		}
	}

	@media print {
		gap: 7px;

		min-width: 672px !important;

		// margin-left: -1px;

		&_resize {
			width: 665px !important;
			min-width: 665px !important;
		}

		table {
			// все заголовки одного стиля при печати
			th {
				height: var(--size-tabel-header);
				max-height: var(--size-tabel-header);

				line-height: 1;

				border: 1px solid black !important;
				border: 1px solid red;
			}

			// верхний блок плана (план шт, НР на кг, НР на м, К выдаче м, Подпись, ФИО ПДС) ячейки
			.plan_top_block_td {
				td {
					height: 35px !important;

					font-size: var(--font-size-cell);
					font-weight: var(--font-weight-cell);

					border: 1px solid black !important;
				}
			}

			// основной блок плана (Выдано, возврат) ячейки (при редактировании)
			.plan_middle_block_td {
				td {
					height: 26px !important;

					font-size: var(--font-size-cell);
					font-weight: var(--font-weight-cell);
					text-align: left;

					border: 1px solid black !important;
				}
			}

			// основной блок плана (Выдано, возврат) ячейки (при создании)
			.create_plan_middle_block_td {
				td {
					height: 25px !important;

					font-size: var(--font-size-cell);
					font-weight: var(--font-weight-cell);
					text-align: left;

					border: 1px solid black !important;
				}
			}
		}

		.print_bold_font {
			// font-size: 16px !important;
			// font-weight: bold;
		}
	}
}

.edit-table > tr > td {
	padding: 0;
	div {
		height: 100% !important;
	}
	& > input,
	select,
	textarea,
	div > input {
		width: 100% !important;
		height: 100% !important;
		padding: 6px;

		border: none;
		border-radius: 0;
		&:hover:not(:focus) {
			cursor: pointer;

			background-color: #edf2f7;
		}
	}
	& > :global(.chakra-select__wrapper) {
		height: 100% !important;
	}
}

.plan-preform {
	@include tech-map;

	table {
		td {
			height: 35px;
		}
	}

	@media print {
		@include tech-map-print;

		table {
			td {
				overflow: hidden;

				height: 26px !important;
			}
		}
	}
}
