.comments {
  overflow-y: scroll;

  max-height: 20vh;

  font-size: 12px;
  font-weight: 500;

  @media (min-width: 96em) {
    max-height: 40vh;
  }
}