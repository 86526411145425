.loader {
	&-position-absolute {
		position: absolute;
		z-index: 1;

		display: grid;
		place-items: center;

		width: 100%;
		height: 100%;
	}

	&-position-fixed {
		position: fixed;
		z-index: 9999;
		top: 0;
		left: 0;

		display: grid;
		place-items: center;

		width: 100%;
		height: 100%;
	}

  &-with-overlay {
    background-color: var(--chakra-colors-surface-blackout);
  }
}
