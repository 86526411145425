.product {
	th {
		vertical-align: middle;
	}

	table {
		td {
			overflow: hidden;

			height: 35px;
		}
	}

	@media print {
		table {
			tbody {
				tr {
					overflow: hidden;

					height: 26px;
					padding: 0;

					text-overflow: ellipsis;
					white-space: nowrap;
				}
			}

			td {
				height: 26px !important;

				font-size: var(--font-size-cell) !important;
				font-weight: var(--font-weight-cell);
				line-height: 1;

				border: 1px solid black !important;
			}

			th {
				height: var(--size-tabel-header);
				max-height: var(--size-tabel-header);

				font-size: var(--font-size-title) !important;
				font-weight: var(--font-weight-title) !important;
				vertical-align: middle;

				border: 1px solid black !important;
			}
		}
	}
}
