.side-modal-link-operation-table {
	td,
	th {
		border: 1px solid #e2e8f0;
	}

	th {
		padding: 0 5px;

		font-size: 12px;
		font-weight: 700;
		font-style: normal;
		line-height: 16px;
		color: #1a202c;
		text-align: start;
		text-transform: none;
		letter-spacing: 0.05em;
		vertical-align: top;
	}
	td {
		padding: 8px;

		font-size: 14px;
		font-weight: 500;
		font-style: normal;
		line-height: 1;
		color: #2d3748;
		text-align: left;
		&:empty {
			padding: 15px;
		}
		input {
			font-size: 14px !important;
		}
		@media print {
			padding: 4px !important;

			&:empty {
				padding: 11px !important;
			}
		}
	}
}
