.dropdown-filter-wraper {
	cursor: default;

	display: flex;
	align-items: center;
	justify-content: center;
	
	width: 100%;
	height: 72px;

	color: #a0aec0;
}
