.badge {
	display: flex;
	align-items: center;

	height: 20px;
	padding-inline: 6px;

	background-color: var(--chakra-colors-danger-400);
	border-radius: var(--chakra-radii-full);

	&_count {
		font-size: var(--chakra-fontSizes-xs);
		font-weight: var(--chakra-fontWeights-semibold);
		color: var(--chakra-colors-white);
	}

	&_icon {
		position: relative;
	}
}
