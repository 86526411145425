$breakpoint-xs: 568px;
$breakpoint-sm: 744px;
$breakpoint-md: 1133px;
$breakpoint-lg: 1400px;
$content-width-sm: 488px;
$content-width-md: 634px;
$content-width-lg: 893px;
$content-width-desktop: 960px;
$header-height: 100px;
$footer-height: 72px;
$regular-padding: 16px;

.layout {
	position: relative;

	min-height: 100dvh;
	padding-top: calc($header-height + $regular-padding);
	padding-bottom: calc($footer-height + $regular-padding);

	background: var(--chakra-colors-unchangeable-white);

	.header {
		position: fixed;
		z-index: 30;
		top: 0;
		left: 0;

		display: flex;
		justify-content: center;

		box-sizing: content-box;
		width: 100%;
		height: $header-height;

		background: var(--surface-front, #fff);

		&.scrolled {
			border-bottom: 1px solid var(--gray-300, #cbd5e0);
		}

		.header-content {
			width: 100%;
			max-width: $content-width-desktop;

			@media (max-width: $breakpoint-md) {
				padding: 0 $regular-padding;
			}
		}
	}
	.children {
		display: flex;
		justify-content: center;

		box-sizing: border-box;

		.children-content {
			width: 100%;
			max-width: $content-width-desktop;

			@media (max-width: $breakpoint-md) {
				padding: 0 $regular-padding;
			}
		}

		@media (min-width: $breakpoint-xs) {
			margin-bottom: 0;
		}
	}
	.footer {
		position: fixed;
		z-index: 30;
		bottom: 0;
		left: 0;

		box-sizing: border-box;
		box-sizing: content-box;
		width: 100%;

		background: var(--surface-front, #fff);

		.footer-content {
			display: flex;
			justify-items: stretch;

			width: 100%;
			max-width: $content-width-desktop;
			padding: 20px $regular-padding;

			@media (min-width: $breakpoint-md) {
				padding: 20px 0 0;
			}
		}

		@media (max-width: $breakpoint-xs) {
			&.scroll-end {
				border-top: 1px solid var(--gray-300, #cbd5e0);
			}
		}

		@media (min-width: $breakpoint-xs) {
			position: relative;
			z-index: 1;

			display: flex;
			justify-content: center;

			background-color: transparent;
		}
	}
}
