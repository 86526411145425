@import '~madsoft-icons/fonts/basic-default/style';
@import '~madsoft-icons/fonts/basic-small/style';
@import './shared/assets/fonts/index';

:root {
	--font-weight-cell: 400;
	--font-weight-title: 400;
	--font-size-cell: 18px;
	--font-size-title: 14px;
	--test-color: black;
	--size-tabel-header: 22px;
}

.h1 {
	font-size: 18px !important;
	font-weight: 600;
	line-height: 28px !important;
	color: var(--chakra-colors-gray-800);
}
