.link-techcard-form {
	display: flex;
	flex-direction: column;
	gap: 12px;
}

.select {
	width: 100%;

	border: 1px solid #e2e8f0;
	border-radius: 6px;

	transition: 0.3s;
	&:hover {
		border: 1px solid #2d3748;
	}

	&-focus {
		border: 1px solid #0f9cfa;
		&:hover {
			border: 1px solid #0f9cfa;
		}
	}
	&-disabled {
		border: 1px solid #edf2f7;
	}
	&-invalid {
		border: 1px solid #fc5e6e;
		&:hover {
			border: 1px solid #fc5e6e;
		}
	}

	span {
		padding-left: 6px !important;

		font-weight: 500;
		color: var(--chakra-colors-gray-400) !important;
	}
}

:global {
	.ant-select {
		.ant-select-selection-item {
			padding-left: 3px !important;

			color: var(--chakra-colors-gray-800) !important;
		}
	}
}
