.operation {
	position: relative;

	&[draggable='true'] {
		border-radius: 4px;
		box-shadow: 10px 5px 5px #e2e8f0;
	}

	&:not(.operation_not-draggable):hover {
		cursor: pointer;

		& .operation-step-id {
			cursor: grab;
			&-number {
				display: none;
			}
			&-icon {
				display: block;

				width: 20px;
				height: 20px;
			}
		}
	}

	&-content {
		display: flex;
		gap: 4px;
		align-items: flex-start;

		width: 100%;

		&-data {
			flex: 1;
		}
	}

	&-step-id {
		display: flex;
		align-items: center;

		width: 27px;
		height: 32px;

		&-number {
			font-family: 'Source Code Pro';
			font-size: 14px;
			font-weight: 400;
			font-style: normal;
			line-height: 20px;
			color: var(--chakra-colors-gray-800);
		}
		&-icon {
			display: none;
		}
	}
}
