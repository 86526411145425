@font-face {
	font-family: Raleway;
	font-weight: 100;
	font-style: normal;
	font-display: swap;
	src: url('Raleway/Raleway-Thin.woff2') format('woff2'), url('Raleway/Raleway-Thin.woff') format('woff'),
		url('Raleway/Raleway-Thin.ttf') format('truetype');
}

@font-face {
	font-family: Raleway;
	font-weight: 200;
	font-style: normal;
	font-display: swap;
	src: url('Raleway/Raleway-ExtraLight.woff2') format('woff2'), url('Raleway/Raleway-ExtraLight.woff') format('woff'),
		url('Raleway/Raleway-ExtraLight.ttf') format('truetype');
}

@font-face {
	font-family: Raleway;
	font-weight: 300;
	font-style: normal;
	font-display: swap;
	src: url('Raleway/Raleway-Light.woff2') format('woff2'), url('Raleway/Raleway-Light.woff') format('woff'),
		url('Raleway/Raleway-Light.ttf') format('truetype');
}

@font-face {
	font-family: Raleway;
	font-weight: 400;
	font-style: normal;
	font-display: swap;
	src: url('Raleway/Raleway-Regular.woff2') format('woff2'), url('Raleway/Raleway-Regular.woff') format('woff'),
		url('Raleway/Raleway-Regular.ttf') format('truetype');
}

@font-face {
	font-family: Raleway;
	font-weight: 500;
	font-style: normal;
	font-display: swap;
	src: url('Raleway/Raleway-Medium.woff2') format('woff2'), url('Raleway/Raleway-Medium.woff') format('woff'),
		url('Raleway/Raleway-Medium.ttf') format('truetype');
}

@font-face {
	font-family: Raleway;
	font-weight: 600;
	font-style: normal;
	font-display: swap;
	src: url('Raleway/Raleway-SemiBold.woff2') format('woff2'), url('Raleway/Raleway-SemiBold.woff') format('woff'),
		url('Raleway/Raleway-SemiBold.ttf') format('truetype');
}

@font-face {
	font-family: Raleway;
	font-weight: 700;
	font-style: normal;
	font-display: swap;
	src: url('Raleway/Raleway-Bold.woff2') format('woff2'), url('Raleway/Raleway-Bold.woff') format('woff'),
		url('Raleway/Raleway-Bold.ttf') format('truetype');
}

@font-face {
	font-family: Raleway;
	font-weight: 800;
	font-style: normal;
	font-display: swap;
	src: url('Raleway/Raleway-ExtraBold.woff2') format('woff2'), url('Raleway/Raleway-ExtraBold.woff') format('woff'),
		url('Raleway/Raleway-ExtraBold.ttf') format('truetype');
}

@font-face {
	font-family: Raleway;
	font-weight: 900;
	font-style: normal;
	font-display: swap;
	src: url('Raleway/Raleway-Black.woff2') format('woff2'), url('Raleway/Raleway-Black.woff') format('woff'),
		url('Raleway/Raleway-Black.ttf') format('truetype');
}

// Source Code Pro (mono)

@font-face {
	font-family: 'Source Code Pro';
	font-weight: 400;
	font-style: normal;
	font-display: swap;
	src: url('SourceCodePro/SourceCodePro-Regular.woff2') format('woff2'),
		url('SourceCodePro/SourceCodePro-Regular.woff') format('woff'),
		url('SourceCodePro/SourceCodePro-Regular.ttf') format('truetype');
}

@font-face {
	font-family: 'Source Code Pro';
	font-weight: 500;
	font-style: normal;
	font-display: swap;
	src: url('SourceCodePro/SourceCodePro-Medium.woff2') format('woff2'),
		url('SourceCodePro/SourceCodePro-Medium.woff') format('woff'),
		url('SourceCodePro/SourceCodePro-Medium.ttf') format('truetype');
}

@font-face {
	font-family: 'Source Code Pro';
	font-weight: 600;
	font-style: normal;
	font-display: swap;
	src: url('SourceCodePro/SourceCodePro-SemiBold.woff2') format('woff2'),
		url('SourceCodePro/SourceCodePro-SemiBold.woff') format('woff'),
		url('SourceCodePro/SourceCodePro-SemiBold.ttf') format('truetype');
}
