.link {
	color: var(--chakra-colors-primary-400);
}

.table {
	&__id {
		width: 160px;
	}

	&__map-link,
	&__status,
	&__opened-at,
	&__tech-map,
	&__symbol,
	&__stuck-operation-at {
		width: 160px;

		text-wrap: wrap;
	}

	&__responsible {
		min-width: 200px;
		max-width: calc(100vw / 8);
	}

	&__operation {
		min-width: 290px;
		max-width: calc(100vw / 9);
	}

	&__product {
		min-width: 320px;
		max-width: calc(100vw / 6);
	}

	&__executor {
		min-width: 200px;
		max-width: calc(100vw / 8);
	}

	&__actions {
		width: 110px;
	}
}
