@import '../../tech-card.module';

.custom-select {
	& > :global(.ant-select-selector) {
		background-color: transparent !important;
		border-radius: 0 !important;
	}
}

.num-block {
	@include tech-map;

	table {
		td {
			height: 35px;

			vertical-align: middle;
		}
	}

	@media print {
		@include tech-map-print;
		@include tech-map-cells;

		table {
			td {
				overflow: hidden;

				max-height: 35px;
			}
		}
	}
}
