.background {
	position: fixed;
	z-index: 99999;
	top: 0;
	left: 0;

	display: flex;
	align-content: center;
	justify-content: center;

	width: 100vw;
	height: 100dvh;
	padding: 16px;

	background-color: var(--chakra-colors-white);

	&_disable {
		position: relative;

		width: 100%;
		height: 100%;
		padding: 16px;

		background-color: none;
	}
	.container {
		display: flex;
		flex-direction: column;
		gap: 32px;
		align-items: center;
		justify-content: center;

		box-sizing: border-box;
		width: 100%;
		max-width: 470px;
		height: 100%;

		.text-wrapper {
			display: flex;
			flex-direction: column;
			gap: var(--chakra-radii-lg, 8px);
			align-self: stretch;
		}

		.footer {
			width: 100%;

			@media (max-width: 568px) {
				position: absolute;
				bottom: 16px;

				padding: 0 16px;
			}
		}

		@media (max-width: 744px) {
			max-width: 360px;
		}
		@media (max-width: 568px) {
			max-width: 288px;
		}
	}
}
