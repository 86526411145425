.cellhoc {
	overflow: hidden;

    box-sizing: border-box;

	// width: 100%;
    width: auto;

	text-overflow: ellipsis;
	white-space: nowrap;
}
