.custom-select {
	& > :global(.ant-select-selector) {
		background-color: transparent !important;
		border-radius: 0 !important;
	}
}

.operations {
	width: 100%;

	// min-width: 1840px;

	@media print {
		// width: 1628px !important;
		min-width: 1628px !important;

		button {
			display: none;
		}

		table {
			td {
				max-height: 32px;

				font-size: var(--font-size-cell) !important;
				font-weight: var(--font-weight-cell);

				//font-size: 16px;

				border: 1px solid black !important;
			}

			th {
				font-size: var(--font-size-title) !important;
				font-weight: var(--font-weight-title);
				line-height: 1;

				border: 1px solid black !important;
			}
		}
	}
}

.date-style {
	padding: 4px 11px !important;
}

.field-disabled {
	background: var(--chakra-colors-gray-100);
	border: none;
}

.edit-table > tr > td {
	padding: 0;
	div {
		height: 100% !important;
	}
	& > input,
	select,
	textarea,
	div > input {
		width: 100% !important;
		height: 100% !important;
		padding: 6px;

		border: none;
		border-radius: 0;
		&:hover:not(:focus) {
			cursor: pointer;

			background-color: #edf2f7;
		}
	}
	& > :global(.chakra-select__wrapper) {
		height: 100% !important;
	}
}

:global(.ant-picker-today-btn) {
	display: block;

	width: 100%;
	height: 100%;
}

.active-row {
	background-color: var(--chakra-colors-gray-200);
}
