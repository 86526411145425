@mixin set-th($min, $max) {
	min-width: $min;
	max-width: $max;
}

.num {
	@include set-th(80px, 100px);
}
.date-of-delivery {
	@include set-th(200px, calc((100vw / 100) * 8));
}
.invoice-number {
	@include set-th(220px, calc((100vw / 100) * 11));
}
.symbol {
	@include set-th(100px, calc(100vw / 3));
}
.gost-and-sortament {
	@include set-th(150px, calc((100vw / 100) * 11));
}

.certificate {
	@include set-th(150px, calc((100vw / 100) * 11));
}

.quantity {
	@include set-th(150px, calc((100vw / 100) * 11));
}

.remainder {
	@include set-th(150px, calc((100vw / 100) * 11));
}
.batch_number {
	@include set-th(150px, calc((100vw / 100) * 11));
}

.number_of_melt {
	@include set-th(150px, calc((100vw / 100) * 11));
}

.divisions {
	@include set-th(160px, calc((100vw / 100) * 11));
}

.n_b {
	@include set-th(160px, calc((100vw / 100) * 11));
}

.n-sigma {
	@include set-th(160px, calc((100vw / 100) * 8));
}
.date-of-manufacture {
	@include set-th(160px, calc((100vw / 100) * 8));
}

.valid_until {
	@include set-th(150px, calc((100vw / 100) * 11));
}

.validity {
	@include set-th(150px, calc((100vw / 100) * 11));
}
.supplier {
	@include set-th(200px, calc((100vw / 100) * 11));
}
.manufacturer {
	@include set-th(200px, calc((100vw / 100) * 11));
}
.comment {
	@include set-th(150px, calc((100vw / 100) * 11));
}
