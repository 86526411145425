.link {
	color: var(--chakra-colors-primary-400);
}

.tech-card-name {
	overflow: hidden;

	text-overflow: ellipsis;
	white-space: nowrap;
}

.number,
.group,
.type,
.preform_number {
	width: 100px;
}

.status {
	width: 150px;
}

.labeling {
	min-width: 200px;
	max-width: calc(100vw / 5);
}

.naming,
.preform_name {
	min-width: 200px;
	max-width: calc(100vw / 5);
}

.created-at {
	min-width: 160px;
	max-width: calc((100vw / 100) * 9);
}
.storage-at {
	min-width: 180px;
	max-width: calc((100vw / 100) * 9);
}

.current-details-count {
	min-width: 140px;
	max-width: calc((100vw / 100) * 8);
}

.wasted {
	min-width: 90px;
	max-width: calc((100vw / 100) * 6);
}

.remaining_count {
	min-width: 140px;
	max-width: calc((100vw / 100) * 6);
}

.current_operation {
	min-width: 130px;
	max-width: calc((100vw / 100) * 15);
}

.preform {
	min-width: 180px;
	max-width: calc((100vw / 100) * 9);
}

.preform_num {
	min-width: 90px;
	max-width: calc((100vw / 100) * 6);
}

.preform_material {
	min-width: 140px;
	max-width: calc((100vw / 100) * 6);
}

.preform_gost,
.preform_gost_sortament {
	width: 200px;
}

.preform_sortament {
	min-width: 130px;
	max-width: calc((100vw / 100) * 15);
}

.preform_status {
	width: 150px;
}

.preform_created-at {
	min-width: 160px;
	max-width: calc((100vw / 100) * 9);
}

.preform_current-details-count,
.preform_wasted,
.preform_return_count,
.preform_remaining_count {
	width: 90px;
}
