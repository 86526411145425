@import '../../shared/constants/breakpoints.module';

.login {
	display: flex;
	align-items: center;
	justify-content: center;

	width: 100vw;
	height: 100dvh;

	background: var(--chakra-colors-unchangeable-white);

	&__background {
		@include media-md {
			display: none;
		}
		width: 100%;
		height: 100%;
		& img {
			display: inline-block;

			height: 100%;
		}

		.helicopter {
			position: absolute;
			top: 0;
			left: 0;

			width: auto;

			object-fit: contain;
			object-position: top;
		}
	}

	&__right-block {
		display: flex;
		align-items: center;
		justify-content: center;

		width: 100%;

		.form {
			@include media-md {
				max-width: 540px;
			}

			@include media-xs {
				max-width: none;
				margin: 0 16px;
			}
			display: flex;
			flex-direction: column;
			gap: 12px;
			align-items: center;

			width: 100%;
			max-width: 388px;
			height: 275px;
		}
	}
}
