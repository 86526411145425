.form {
  &__field {
    gap: 0.5rem;

    margin-top: 1.5rem;
  }

  &__title {
    margin-bottom: 0.5rem;

    font-weight: 600;
  }
}