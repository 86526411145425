:global {
	@media print {
		#print-area {
			transform: scale(1) !important;
		}
	}
}

:global(.ant-picker) {
	padding-block: 6px;
	padding-inline: 12px;

	border: none;
	border-bottom: 1px solid var(--gray-200, #e2e8f0);
	border-radius: 0;
}

:global(.ant-select-selector) {
	border-radius: 0;
}

:global(.ant-picker-focused) {
	border-radius: 0;
	outline: none;
	box-shadow: none !important;
}

:global(.ant-picker-input) {
	font-size: 14px;
	color: var(--gray-400);

	outline: none;

	input {
		color: var(--gray-400) !important;
	}
}

.tech-card {
	display: flex;
	flex-direction: column;
	gap: 24px;

	width: 100%;
	min-width: 1000px;
	padding: 24px;

	&__assembly {
		padding-top: 16px !important;
	}

	// гэп между операциями и СГД (на печати он должен быть другой)
	.operations_sgd_flex {
		gap: 24px;

		min-width: 1848px;
	}

	.main_flex {
		min-width: 1848px;
	}

	.history-operations-print {
		display: none;

		@media print {
			display: block;

			font-size: 12px !important;
		}
	}

	th,
	td {
		border: 1px solid #e2e8f0;
		:global(.ant-picker) {
			flex: 1 !important;

			border: none !important;
			border-radius: 0 !important;
			&:hover:not(:global(.ant-picker-focused)) {
				cursor: pointer !important;

				background-color: #edf2f7 !important;
			}
		}
		:global(.ant-picker-focused) {
			background-color: inherit !important;
			outline: 1px solid #0f9cfa !important;
			box-shadow: none !important;
		}
	}

	td:has(input),
	th:has(input),
	td:has(textarea),
	td:has(:global(.chakra-select__wrapper)) {
		padding: 0;
		div {
			height: 100% !important;
		}
		& > input,
		select,
		textarea,
		div > input {
			width: 100% !important;
			height: 100% !important;
			padding: 6px;

			border: none;
			border-radius: 0;
			&:hover:not(:focus) {
				cursor: pointer;

				background-color: #edf2f7;
			}
		}
		& > :global(.chakra-select__wrapper) {
			height: 100% !important;
		}
	}

	th {
		padding: 0 5px;

		font-size: 12px;
		font-weight: 700;
		font-style: normal;
		line-height: 16px;
		color: #2d3748;
		text-align: center;
		text-transform: none;
		letter-spacing: 0.05em;
		vertical-align: bottom;

		@media print {
			font-size: var(--font-size-title);
			font-weight: var(--font-weight-title);
			color: black;
		}
	}
	td {
		padding: 8px;

		font-size: 14px;
		font-weight: 500;
		font-style: normal;
		line-height: 1;
		color: #2d3748;
		text-align: center;
		&:empty {
			padding: 15px;
		}
		input {
			font-size: 14px !important;
		}
		@media print {
			padding: 4px !important;

			font-size: var(--font-size-cell);
			font-weight: var(--font-weight-cell);
			color: black;

			&:empty {
				padding: 11px !important;
			}
		}
	}

	@media print {
		gap: 8px;

		.operations_sgd_flex {
			gap: 8px;

			min-width: 1632px;
		}

		.main_flex {
			min-width: 1632px;
		}
	}
}
.focused {
	position: relative;
	z-index: 1;

	animation: 2s focus 0s ease-out;
	@media print {
		animation: unset;
	}
}
@keyframes focus {
	0% {
		outline: 4px solid #0f9cfa;
	}
	50% {
		outline: 4px solid #0f9cfa;
	}
	75% {
		outline: 4px solid #0f9cfa;
	}
	99% {
		outline: 4px solid #0f9cfa;
	}
	100% {
		outline: none;
	}
}
:global(.chakra-popover__popper) > section:focus-visible {
	outline: none !important;
	box-shadow: none;
}

@mixin tech-map-print {
	table {
		td {
			font-size: var(--font-size-cell) !important;
			font-weight: var(--font-weight-cell);
			line-height: 1;

			border: 1px solid black !important;
		}

		th {
			height: var(--size-tabel-header);
			max-height: var(--size-tabel-header);

			font-size: var(--font-size-title) !important;
			font-weight: var(--font-weight-title) !important;

			border: 1px solid black !important;
		}
	}
}

@mixin tech-map {
	table {
		th {
			height: var(--size-tabel-header);
			max-height: var(--size-tabel-header);

			line-height: 1;
			vertical-align: middle;
		}
	}
}

@mixin tech-map-cells {
	overflow: hidden;

	padding: 0;

	text-overflow: ellipsis;
	white-space: nowrap;
}
