.wrapper {
	display: flex;
	flex: 1;
	flex-direction: column;

	width: 100%;
	height: 100%;
	padding: 24px;

	&::-webkit-scrollbar {
		width: 16px;
		height: 16px;

		background-color: inherit;
	}

	&::-webkit-scrollbar-thumb {
		background: #e2e8f0;
		border: 4px solid white;
		border-radius: 12px;
	}
}

.table {
	& tr td:first-of-type {
		border-radius: 12px 0 0 12px;
	}
	& tr td:last-of-type {
		border-radius: 0 12px 12px 0;
	}
	& * {
		border: none !important;
	}
}

:global {
	& .ant-table * {
		font-family: 'Source Code Pro';
	}

	.ant-table-body {
		padding-bottom: 20px;
	}

	.ant-table-container::after,
	.ant-table-container::before {
		display: none;
	}

	.ant-table-wrapper
		.ant-table-thead
		> tr
		> th:not(:last-child, .ant-table-selection-column, .ant-table-row-expand-icon-cell, [colspan])::before {
		width: 0 !important;
	}
	.ant-table-thead .ant-table-cell {
		padding: 12px !important;

		font-size: 16px;
		font-weight: 500 !important;
		color: var(--chakra-colors-gray-800) !important;
		vertical-align: top;

		background-color: var(--chakra-colors-white) !important;

		&::after {
			content: '';

			position: absolute;
			top: unset;
			bottom: 0;
			left: 12px;
			transform: unset;

			width: calc(100% - 24px);
			height: 1px;

			background-color: #e5e9f5;
		}
	}
	.ant-table-cell {
		overflow: hidden;

		text-overflow: ellipsis;
		white-space: nowrap;
	}

	.ant-table-column-title {
		height: 40px;

		font-size: 16px;
		font-weight: 500;
		color: var(--chakra-colors-gray-800);
	}

	.ant-table-thead th:hover {
		background-color: var(--chakra-colors-special-hover-secondary) !important;
		border-radius: 4px 4px 0 0;
	}

	.ant-table-column-sorter {
		height: 40px;
		padding: 2px;

		color: var(--chakra-colors-gray-700) !important;

		& * ::before {
			width: 0 !important;
			height: 0 !important;

			color: transparent !important;
		}
	}
	.ant-table-column-sorter-up.active {
		color: var(--chakra-colors-gray-400) !important;
	}
	.ant-table-column-sorter-down.active {
		color: var(--chakra-colors-gray-400) !important;
	}

	.ant-table-tbody .ant-table-row:nth-child(odd) {
		background-color: #f8fafd;
		border: none !important;
		outline: none !important;

		& > .ant-table-cell-fix-right,
		.ant-table-cell-fix-left {
			background-color: #f8fafd;
		}
	}

	.ant-table-tbody .ant-table-row:nth-child(even) {
		background-color: var(--chakra-colors-white);
		border: none !important;
		outline: none !important;

		& > .ant-table-cell-fix-right,
		.ant-table-cell-fix-left {
			background-color: var(--chakra-colors-white);
		}
	}

	.ant-table-tbody .ant-table-row {
		& .ant-table-cell {
			&::after {
				content: '';

				position: absolute;
				top: 12px;
				right: 1px;

				width: 1px;
				height: calc(100% - 24px);

				background-color: #e5e9f5;
			}
			.text-align-right {
				text-align: right;
			}
		}

		& .ant-table-cell:not(:first-of-type, :last-of-type)::after {
			right: 1px;
		}

		& .ant-table-cell-fix-left::after {
			right: 1px;
		}

		& .ant-table-cell-fix-right::after {
			left: 1px;
		}
	}

	.ant-table-tbody .ant-table-row:nth-child(odd):hover > td {
		background-color: var(--chakra-colors-special-hover-secondary) !important;
	}
	.ant-table-tbody .ant-table-row:nth-child(even):hover > td {
		background-color: var(--chakra-colors-special-hover-primary);
	}
	.ant-table-row .ant-table-cell {
		padding: 8px 12px !important;

		font-size: 16px;
		font-weight: 400;
		line-height: 28px !important;
		color: var(--chakra-colors-gray-800);
	}
}

:global(.ant-table-tbody .ant-table-row:nth-child(odd)) > .column-hovered {
	background-color: var(--chakra-colors-special-hover-secondary) !important;
	border-radius: 0;
}
:global(.ant-table-tbody .ant-table-row:nth-child(even)) > .column-hovered {
	background-color: var(--chakra-colors-special-hover-primary) !important;
	border-radius: 0;
}
:global(.ant-pagination-item) {
	border: none !important;
}
:global(.ant-select-selector) {
	border: none !important;
	box-shadow: none !important;
}
