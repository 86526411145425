body {
	margin: 0;
	padding: 0;

	background: var(--chakra-colors-surface-back) !important;

	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

#root,
.App {
	width: 100%;
	height: 100%;
}

.ant-input__placeholder {
	color: blue;
}

.chakra-alert {
	align-items: center !important;
}

* {
	font-family: Raleway, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Fira Sans',
		'Droid Sans', 'Helvetica Neue', sans-serif;
	font-variant-numeric: lining-nums proportional-nums;
}

table * {
	font-family: 'Source Code Pro' !important;

	@media print {
		font-family: 'Times New Roman' !important;
	}
}
