.react-flow__edge .react-flow__edge-path {
	stroke: #3d3d3d;
	stroke-width: 1;
}

.react-flow__handle.source {
	visibility: hidden;
}

.react-flow__handle.target {
	visibility: hidden;
}
