.link {
  font-size: 14px;
  color: var(--chakra-colors-primary-400);
}

.block {
  font-family: Raleway, serif;

  &__label {
    font-size: 12px;
    color: var(--chakra-colors-gray-500)
  }

  &__field {
    font-size: 14px;
    font-weight: 500;
    color: var(--chakra-colors-gray-1000);
  }
}
