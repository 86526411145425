@import '../../tech-card.module';

.sgd {
	// min-width: 1840px;

	@media print {
		// width: 1628px !important;
		min-width: 1628px !important;

		table {
			td {
				max-height: 32px;

				font-size: var(--font-size-cell) !important;
				font-weight: var(--font-weight-cell) !important;

				//font-size: 16px !important;

				border: 1px solid black !important;
			}
			th {
				font-size: var(--font-size-title) !important;
				font-weight: var(--font-weight-title) !important;
				line-height: 16px;

				border: 1px solid black !important;
			}
		}

		button {
			visibility: hidden;
		}
	}
}

.custom-select {
	& > :global(.ant-select-selector) {
		background-color: transparent !important;
		border-radius: 0 !important;
	}
}

.edit-table > tr > td {
	padding: 0;
	div {
		height: 100% !important;
	}
	& > input,
	select,
	textarea,
	div > input {
		width: 100% !important;
		height: 100% !important;
		padding: 6px;

		border: none;
		border-radius: 0;
		&:hover:not(:focus) {
			cursor: pointer;

			background-color: #edf2f7;
		}
	}
	& > :global(.chakra-select__wrapper) {
		height: 100% !important;
	}
}

.receiver-select-group {
	display: flex;
	align-items: center;

	width: 100%;
	padding: 4px 6px 4px 12px;
}

.sgd-preform {
	@include tech-map;

	@media print {
		@include tech-map-print;
	}
}
