.header {
	display: flex;
	flex-direction: column;
	gap: var(--chakra-radii-lg, 8px);

	padding: 24px 0;

	.bottom-block {
		display: flex;
		justify-content: space-between;
	}
}
