$breakpoint-sm: 568px;
$breakpoint-md: 744px;
$breakpoint-lg: 1366px;

@mixin media-xs {
	@media (max-width: $breakpoint-sm) {
		@content;
	}
}

@mixin media-sm {
	@media (max-width: $breakpoint-md) {
		@content;
	}
}

@mixin media-md {
	@media (max-width: $breakpoint-lg) {
		@content;
	}
}

@mixin media-lg {
	@media (min-width: $breakpoint-lg) {
		@content;
	}
}
