.input-group {
	display: flex;
	flex-direction: column;
	gap: 8px;

	&_label {
		font-weight: 600;
		line-height: var(--chakra-lineHeights-6);
	}
}

.form {
	display: flex;
	flex-direction: column;
	gap: 24px;
}
