.form {
	&__group {
		display: flex;
		flex-direction: column;
		gap: 12px;

		margin-bottom: 20px;
	}

	&__grid {
		display: grid;
		grid-auto-flow: row;
		grid-template-columns: 1fr 1fr;
		gap: 12px;
	}

	&__raw {
		display: grid;
		grid-auto-flow: row;
		grid-template-columns: 1fr 1fr 1fr 1fr;
		gap: 12px;
	}

	&__operation {
		display: flex;
		flex-direction: column;
		gap: 12px;

		&-toprow {
			display: flex;
			align-items: center;
			justify-content: space-between;

			margin-bottom: 10px;
		}

		&-content {
			display: flex;
			gap: 4px;
			align-items: center;
		}
		&-step-id {
			&-number {
				display: grid;
				place-items: center;

				width: 27px;
				height: 20px;

				font-family: 'Source Code Pro';
			}
			&-icon {
				display: none;
			}
		}

		&-name {
			&::placeholder {
				font-size: 14px !important;
				line-height: 20px !important;
				color: #a0aec0;
			}
		}
		&-icon {
			cursor: pointer;

			position: absolute;
			top: 7px;
			left: -22px;

			opacity: 0;

			transition: 0.3s;
		}
	}
}

.label {
	position: relative;
}

.label:hover {
	.form__operation-step-id {
		cursor: grab;
		&-number {
			display: none;
		}
		&-icon {
			display: block;

			width: 27px;
			height: 20px;
		}
	}
}
.label[draggable='true'] {
	border-radius: 4px;
	box-shadow: 10px 5px 5px #e2e8f0;
}
