:global {
	.modal-content-container .chakra-modal__content-container {
		justify-content: flex-end !important;
	}
}

.modal {
	height: calc(100vh - 32px);
	margin: 16px !important;

	font-family: Raleway !important;
}
