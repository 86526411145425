.dnd-container {
	cursor: pointer;

	position: relative;

	overflow: hidden;
	display: flex;
	flex-direction: row;
	gap: 4px;
	align-items: center;
	justify-content: center;

	width: 100%;
	height: 56px;
	padding: 12px;

	color: var(--chakra-colors-gray-500);

	border-color: var(--chakra-colors-gray-200);
	border-style: dashed;
	border-width: 2px;
	border-radius: 4px;

	transition: background 0.16s;

	&-content {
		display: flex;
		flex-direction: row;
	}

	&-background {
		position: absolute;
		top: 0;
		left: 0;

		width: 100%;
		height: 100%;

		&-hovered {
			opacity: 0;

			transition: 0.16s;
		}

		& > img {
			width: 100%;
			height: 100%;

			object-fit: cover;
		}
	}

	&_focused {
		border-style: solid;
	}

	&_selected {
		border-width: 0;
	}

	&_disabled {
		pointer-events: none;

		opacity: 0.32;
	}

	&:hover {
		&:not(.dnd-container_selected) {
			border-width: 2px;
		}
	}

	&_accepted {
		cursor: default;

		border-style: solid;
	}
}
