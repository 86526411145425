.sidebar {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;

	padding: 16px 10px;

	background-color: var(--chakra-colors-white);
	border-radius: 12px;
	box-shadow: 0 4px 6px -2px #8e94990d, 0 10px 15px -3px #8e94991a;
}

.flex-container {
	display: flex;
	flex-direction: column;
	gap: 8px;
	align-items: 'center';
}
