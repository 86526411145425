.consumption-block {
	width: 100%;

	// min-width: 220px;
	max-width: 230px;

	// margin-left: -1px;

	// штрих-код
	.qr_code {
		height: 100px;
		max-height: 52px;
	}

	// КС/ИИ
	.kcii {
		height: 60px;
	}

	// КС/ИИ (если два значения)
	.kcii_double {
		height: 60px;
	}

	.print_bold_font {
		//font-weight: bold;
		border-bottom: 1px solid #e2e8f0;
	}

	// техническое решение
	.tech_solution {
		height: 45px;
	}

	table {
		th {
			height: 17px !important;

			line-height: 1;
			vertical-align: middle;
		}

		td {
			height: 35px !important;
		}
	}

	@media print {
		// после margin-left увеличиваем макс ширину на 3 px для печати
		// width: 198px !important;
		// min-width: 198px !important;

		// при печати съезжают margin
		// margin-left: -3px;

		.qr_code {
			padding-bottom: 5px;
		}

		.without_border {
			&_top {
				border-top: none !important;
			}
			&_bottom {
				border-bottom: none !important;
			}
		}

		.print_bold_font {
			//font-weight: bold;
			border-bottom: 1px solid black;
		}

		// техническое решение при печати
		.tech_solution {
			height: 18px !important;
			max-height: 18px !important;

			td {
				height: 18px !important;
				max-height: 18px !important;

				font-size: var(--font-size-cell);
				font-weight : var(--font-weight-cell);
			}
		}

		.kcii {
			height: 39px !important;
			max-height: 39px !important;

			// для вёрстки в режиме печати разделяющей линии в КС/ИИ
			td {
				height: 39px !important;
				max-height: 39px !important;
				padding: 0 !important;

				font-size: var(--font-size-cell);		
				font-weight : var(--font-weight-cell);

				hr {
					background-color: black;
				}
			}
		}

		.kcii_double {
			height: 39px !important;
			max-height: 39px !important;

			// для вёрстки в режиме печати разделяющей линии в КС/ИИ (с разделенным значением)
			td {
				padding: 0 !important;

				font-size: var(--font-size-cell);	
				font-weight : var(--font-weight-cell);

				hr {
					background-color: black;
				}
			}
		}

		table {
			td {
				font-size: var(--font-size-cell);
				font-weight : var(--font-weight-cell);

				border: 1px solid black;
			}

			th {
				height: 16px !important;

				font-size: var(--font-size-title);
				font-weight : var(--font-weight-title);
				line-height: 1;

				border: 1px solid black;
			}
		}
	}
}

.edit-table > tr > td {
	padding: 0;
	div {
		height: 100% !important;
	}
	& > input,
	select,
	textarea,
	div > input {
		width: 100% !important;
		height: 100% !important;
		padding: 4px;

		border: none;
		border-radius: 0;
		&:hover:not(:focus) {
			cursor: pointer;

			background-color: #edf2f7;
		}
	}
	& > :global(.chakra-select__wrapper) {
		height: 100% !important;
	}
}
