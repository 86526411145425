.form {
	display: flex;
	flex-direction: column;
	gap: 12px;

	margin-bottom: 20px;
}

.list {
	& li {
		margin-bottom: 8px;

		font-size: 12px;
		line-height: 16px;
	}

	&__marker {
		&-success::marker {
			color: #32d157;
		}
		&-danger::marker {
			color: #fc5e6e;
		}
		&-gray::marker {
			color: #718096;
		}
	}
}
