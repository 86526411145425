.create-title {
	font-family: Inter;
	font-size: 16px;
	font-weight: 600;
	line-height: 24px;
	white-space: nowrap;
}

.edit-title {
	width: 155px;

	font-size: 16px;
	font-weight: 500;
	line-height: 20px;
	color: var(--chakra-colors-tertiary-600);
	white-space: nowrap;
}

.tech-card__title {
	font-family: Raleway !important;
	font-size: 18px !important;
	font-weight: 600 !important;
	font-style: normal !important;
	font-variant-numeric: lining-nums proportional-nums !important;
	line-height: 28px !important;
	color: var(--gray-800, #1a202c) !important;
	white-space: nowrap;
}

// стили для печати
@page {
	size: auto;

	margin-top: 13mm;
	margin-right: 5mm;
	margin-bottom: 6mm;
}