.pagination {
	display: flex;
	justify-content: space-between;

	width: 100%;

	background-color: var(--chakra-colors-white);
	border-bottom-right-radius: 12px;
	border-bottom-left-radius: 12px;
	:global(.ant-pagination) {
		display: flex;
		justify-content: space-between;

		width: 100%;
		:global(.ant-pagination-total-text) {
			display: flex;
			flex: 1;
			align-items: center;

			font-size: 12px;
			font-weight: 500;
			font-style: normal;
			line-height: 16px;
			color: var(--chakra-colors-gray-800);
		}
		:global(.ant-pagination-item a) {
			padding: 2px;

			font-size: 12px;
			color: var(--chakra-colors-special-pagination-pages);
		}
		:global(.ant-select-arrow .anticon-down) {
			padding-top: 2px;

			color: var(--chakra-colors-special-pagination-pages);
		}
		:global(.ant-pagination-next button) {
			color: var(--chakra-colors-special-pagination-pages) !important;
		}
		:global(.ant-pagination-prev button) {
			color: var(--chakra-colors-special-pagination-pages) !important;
		}
		:global(.ant-pagination-item-active a) {
			color: var(--chakra-colors-special-pagination-active) !important;
		}
		:global(.ant-pagination-options-size-changer) {
			:global(.ant-select-selector) {
				gap: 6px;

				padding: 2px 8px;

				font-size: 12px;
				color: var(--chakra-colors-special-pagination-pages) !important;
			}
		}
		:global(.ant-pagination-item-ellipsis) {
			box-sizing: content-box;
			width: 30px;
			height: 30px;
			margin: 0 !important;

			color: #3d3d3d !important;
		}
		:global(.ant-pagination-item-link-icon) {
			display: grid;
			place-items: center;

			box-sizing: content-box;
			width: 30px;
			height: 30px;

			border: 1px solid #1677ff !important;
			border-radius: 6px;
		}
	}
}
.footer {
	position: relative;

	display: flex;
	flex-direction: column;
	justify-content: flex-end;

	width: 100%;
	margin-top: auto;
	padding: 12px 0 0;
}
.divider {
	position: absolute;
	top: 0;
	left: 0;

	width: 100%;
}
