.date-picker {
	position: relative;
	&__calendar {
		padding-bottom: 50px;
	}
	&__info {
		position: absolute;
		bottom: 8px;

		width: 100%;

		text-align: center;
	}

	&__reset-btn {
		width: calc(100% - 20px);
	}
}
